<template>
    <div class="track-wrapper">
        <div class="trackReplay_box">
            <jy-query ref="checkForm" :model="diaform">
                <jy-query-item label="选择车辆" prop="vehicleNosStr">
                    <el-input @focus="clickVehicle" placeholder="请选择" :value="diaform.vehicleNosStr"></el-input>
                </jy-query-item>

                <jy-query-item label="设备类型" prop="type">
                    <el-select v-model="diaform.type">
                        <el-option v-for="op in deviceTypeList" :key="op.value" :label="op.label" :value="op.value"></el-option>
                    </el-select>
                </jy-query-item>

                <jy-query-item label="回放速度" prop="multiple">
                    <el-select v-model="diaform.multiple" placeholder="请选择">
                        <el-option v-for="item in speedList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </jy-query-item>

                <template #rightCol>
                    <el-button type="primary" @click="oncheck">查询</el-button>

                    <el-button type="primary" @click="resetForm('checkForm')">重置</el-button>
                </template>
            </jy-query>

            <jy-query style="margin-top: 10px">
                <jy-query-item label="回放时间" :span="1.6" prop="time">
                    <el-date-picker
                        v-model="diaform.time"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    ></el-date-picker>
                </jy-query-item>

                <template #rightCol>
                    <el-button type="primary" @click="toStart" :disabled="isToStart">回放</el-button>

                    <el-button type="primary" @click="toPause" :disabled="isToPauseStop">暂停</el-button>

                    <el-button type="primary" @click="toStop" :disabled="isToPauseStop">停止</el-button>
                </template>
            </jy-query>

            <div class="map-wrapper" ref="container"></div>
        </div>

        <vehicle-dialog ref="vehicle" @select="selectVehicles"></vehicle-dialog>
    </div>
</template>

<script>
    import { deviceType } from "@/common/js/wordbook.js";
    import VehicleDialog from "./VehicleDialog.vue";
    import gcoord from "gcoord";
    import LuShu from "@/common/js/BMapLib/LuShu.js";

    import startImg from "@/assets/startIcon.png";
    import endImg from "@/assets/endIcon.png";

    let selectVehicles = [],
        trackMap = null,
        lushuInsArray = [];
    export default {
        mounted() {
            this.$nextTick(() => {
                this.initMap();
            });
        },
        methods: {
            initMap() {
                trackMap = new BMap.Map(this.$refs.container);
                trackMap.enableScrollWheelZoom();
                trackMap.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
            },
            clickVehicle() {
                this.$refs.vehicle.init();
            },
            oncheck() {
                trackMap && trackMap.clearOverlays();
                selectVehicles.forEach(v => {
                    this.addLuShu(v);
                });
                this.addSiteMarkers();
                const routeIds = selectVehicles.map(v => v.orgId);
                routeIds.forEach(v => this.addSiteMarkers(v));
            },
            addSiteMarkers(routeId) {
                this.$http.post("/site/info/queryByRouteId", { routeId }).then(res => {
                    res.detail.forEach(site => {
                        const { routeSite, siteInfo } = site;
                        const [lng, lat] = gcoord.transform([siteInfo.lng, siteInfo.lat], gcoord.WGS84, gcoord.BD09);
                        const marker = new BMap.Marker(new BMap.Point(lng, lat));
                        const labelText = siteInfo.cName + "(" + (routeSite.goType === 1 ? "上行" : "下行") + ")";
                        let label = new BMap.Label(labelText, {
                            offset: new BMap.Size(30, -11)
                        });
                        marker.setLabel(label);

                        trackMap.addOverlay(marker);
                    });
                });
            },
            async addLuShu(vehicle) {
                const { detail: deviceList } = await this.$http.post("/base/device/queryByVehicleId", {
                    vehicleId: vehicle.vehicleId
                });
                const deviceId = this.getDeviceIdByList(deviceList);
                if (deviceId) {
                    const { detail: gpsData } = await this.$http.post("/waybillActual/getGpsDataByDevice", {
                        deviceNo: deviceId,
                        beginTime: this.diaform.time[0],
                        endTime: this.diaform.time[1]
                    });
                    if (Array.isArray(gpsData)) {
                        let arrPosi = [],
                            landmarkPois = [];
                        gpsData.forEach(v => {
                            const [lng, lat] = gcoord.transform([v.lng, v.lat], gcoord.WGS84, gcoord.BD09);
                            arrPosi.push(new BMap.Point(lng, lat));
                            landmarkPois.push({
                                lng: lng,
                                lat: lat,
                                html: `<div style="text-align:left;">
                                        <span>时间：${this.$util.fixedMacTime(v.time)}</span>
                                        <br />
                                        <span>速度：${v.spd}</span>
                                        <br />
                                        <span>行驶里程：${v.mileage}</span>
                                        <br />
                                        <span>经纬度：经度${lng}，纬度${lat}</span>
                                        <br />
                                        <span>方向角：${v.dir}</span>
                                    </div>`
                            });
                        });
                        // 添加首尾标记
                        this.addStartEndMarker(arrPosi[0], arrPosi[arrPosi.length - 1]);

                        trackMap.centerAndZoom(arrPosi[0], 16);
                        // 添加polyline
                        const polyline = new BMap.Polyline(arrPosi, {
                            strokeColor: "blue", //设置颜色
                            strokeWeight: 5, //宽度
                            strokeOpacity: 0.5 //透明度
                        });
                        trackMap.addOverlay(polyline);
                        // 添加路书
                        const lushu = new LuShu(trackMap, arrPosi, {
                            defaultContent: "", //"从天安门到百度大厦"
                            autoView: false, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
                            icon: new BMap.Icon("https://developer.baidu.com/map/jsdemo/img/car.png", new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
                            speed: this.diaform.multiple, //运行速度
                            enableRotation: true, //是否设置marker随着道路的走向进行旋转
                            landmarkPois: landmarkPois,
                            showInfoWindow: true
                        });
                        lushuInsArray.push(lushu);
                        this.isToStart = false;
                    }
                }
            },
            getDeviceIdByList(list) {
                if (Array.isArray(list)) {
                    const newList = list.filter(v => v.dType == this.diaform.type);
                    return newList[0] ? newList[0].deviceId : "";
                }
            },
            addStartEndMarker(startPos, endPos) {
                if (startPos && endPos) {
                    let startPoint = new window.BMap.Point(startPos.lng, startPos.lat);
                    let endPoint = new window.BMap.Point(endPos.lng, endPos.lat);
                    var startIcon = new BMap.Icon(startImg, new BMap.Size(50, 50));
                    var endIcon = new BMap.Icon(endImg, new BMap.Size(50, 50));
                    let marker = new BMap.Marker(startPoint, {
                        icon: startIcon
                    });
                    let endmarker = new BMap.Marker(endPoint, {
                        icon: endIcon
                    });
                    trackMap.addOverlay(marker);
                    trackMap.addOverlay(endmarker);
                }
            },
            resetForm(formName) {
                trackMap && trackMap.clearOverlays();
                this.$refs[formName].resetFields();
                this.diaform.time = [new Date().Format("yyyy-MM-dd ") + "09:37:00", new Date().Format("yyyy-MM-dd ") + "17:37:00"];
                selectVehicles = [];
            },
            toStart() {
                this.isToStart = true;
                this.isToPauseStop = false;
                lushuInsArray.forEach(ins => {
                    ins._opts.speed = this.diaform.multiple; // 修改速度
                    ins.start();
                });
            },
            toPause() {
                this.isToStart = false;
                this.isToPauseStop = true;
                lushuInsArray.forEach(ins => {
                    ins.pause();
                });
            },
            toStop() {
                this.isToStart = false;
                this.isToPauseStop = true;
                lushuInsArray.forEach(ins => {
                    ins.stop();
                });
            },
            selectVehicles(vehicles) {
                this.diaform.vehicleNosStr = vehicles.map(v => v.plateNum).join(",");
                selectVehicles = vehicles;
            }
        },
        created() {
            this.deviceTypeList = deviceType();
            this.diaform.time = [new Date().Format("yyyy-MM-dd ") + "09:37:00", new Date().Format("yyyy-MM-dd ") + "17:37:00"];
        },
        data() {
            return {
                diaform: {
                    time: [],
                    type: 4,
                    vehicleNosStr: "",
                    multiple: 10
                },
                deviceTypeList: [],
                isToStart: true,
                isToPauseStop: true,
                speedList: [
                    {
                        value: 10,
                        label: "1X"
                    },
                    {
                        value: 30,
                        label: "3X"
                    },
                    {
                        value: 50,
                        label: "5X"
                    },
                    {
                        value: 100,
                        label: "10X"
                    }
                    // {
                    //     value: 300,
                    //     label: "300X"
                    // }
                ]
            };
        },
        components: {
            VehicleDialog
        }
    };
</script>

<style lang="scss" scoped>
    .track-wrapper {
        height: 100%;
        .trackReplay_box {
            height: 100%;
            display: flex;
            flex-direction: column;
            .map-wrapper {
                flex: 1;
                height: 100%;
            }
        }
    }
</style>
