<template>
    <el-tree class="vehicle_tree" ref="tree" v-loading="loading" node-key="id" lazy :load="loadNode" :props="treeProps" @node-click="handleNodeClick">
        <span slot-scope="{ node, data }" class="tree_node_span">
            <span class="content_span" :style="{ color: data.online == 1 ? '#2ecc71' : 'black' }">{{ data.text }}</span>
        </span>
    </el-tree>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                treeProps: {
                    label: "text",
                    children: "children",
                    isLeaf: "leaf"
                }
            };
        },
        methods: {
            handleNodeClick(data) {
                this.$emit("select", data);
            },
            loadNode(node, resolve) {
                let id = "",
                    type = 0;
                if (node.data && node.data.id) {
                    id = node.data.id;
                    type = node.data.type;
                }
                this.$http
                    .post("/base/resource/getTree", {
                        id,
                        type
                    })
                    .then(async ({ detail }) => {
                        let children = [],
                            originList = detail[0].children;
                        for (let index = 0; index < originList.length; index++) {
                            const v = originList[index];
                            if (v.type === 0) {
                                children.push({
                                    text: v.data.orgNa,
                                    id: v.data.orgId
                                });
                            }
                        }
                        resolve(children);
                    });
            },
            getOrgNames(id) {
                let node = this.$refs.tree.getNode(id),
                    parent = node.data,
                    orgNames = [];
                while (parent) {
                    if (parent.type === 0) {
                        orgNames.push(parent.text);
                    }
                    parent = parent.pData;
                }
                orgNames.reverse();
                return orgNames.join("");
            }
        }
    };
</script>

<style lang="scss" scoped="left_tree">
    .left_tree {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        height: 100%;

        .vehicle_tree {
            flex: 1;
            overflow-y: auto;
            // max-height: 100%;
            margin-top: 10px;
            overflow-x: hidden;
        }

        .tree_node_span {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
            font-weight: 600;

            .content_span {
                vertical-align: middle;
                color: #333333;

                &.online {
                    color: #36ab60;
                }

                img {
                    vertical-align: middle;
                    margin-right: 2px;
                    width: 16px;
                }
            }
        }
    }
</style>
