import { render, staticRenderFns } from "./TreeCom.vue?vue&type=template&id=3b0c298c&scoped=true"
import script from "./TreeCom.vue?vue&type=script&lang=js"
export * from "./TreeCom.vue?vue&type=script&lang=js"
import style0 from "./TreeCom.vue?vue&type=style&index=0&id=3b0c298c&prod&lang=scss&scoped=left_tree"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0c298c",
  null
  
)

export default component.exports