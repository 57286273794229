<template>
    <el-dialog :visible.sync="visible" title="选择车辆">
        <el-row>
            <el-col :span="6">
                <tree-com @select="selectOrg"></tree-com>
            </el-col>
            <el-col :span="18">
                <jy-query ref="form" :model="form">
                    <jy-query-item prop="plateNum" label="车牌号:" :span="1.5">
                        <el-input placeholder="请输入" v-model="form.plateNum"></el-input>
                    </jy-query-item>
                    <template slot="rightCol">
                        <el-button type="primary" @click="oncheck">查询</el-button>
                        <el-button type="primary" @click="resetForm('form')">重置</el-button>
                    </template>
                </jy-query>
                <div class="choose_box">
                    <div class="left_box">已选车辆:</div>
                    <div class="chooseCar_box">
                        <el-tag style="margin-right: 10px" v-for="tag in batchChooseList" :key="tag.vehicleId" closable @close="closeChoose(tag.vehicleId)">
                            {{ tag.plateNum }}
                        </el-tag>
                    </div>
                </div>
                <jy-table ref="dtable" :data="dataList" v-loading="loading" @select-all="selectchange" @select="selectchange">
                    <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                    <jy-table-column type="selection" width="40"></jy-table-column>
                    <jy-table-column prop="plateNum" label="车牌号"></jy-table-column>
                    <jy-table-column prop="vin" label="vin"></jy-table-column>
                </jy-table>
                <jy-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageIndex"
                    :page-size="pageSize"
                    :total="total"
                ></jy-pagination>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <div>
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="selectSure">确 定</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import TreeCom from "./TreeCom.vue";
    export default {
        mounted() {},
        methods: {
            closeChoose(id) {
                this.batchChooseList = this.batchChooseList.filter(item => item.driverId != id);
                let list = this.dataList.filter(one => one.driverId == id);
                if (list.length) {
                    this.$refs.dtable.toggleRowSelection(list[0], false);
                }
            },
            selectOrg(data) {
                this.form.orgId = data.id;
                this.getList();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.batchChooseList = [];
                this.oncheck();
            },
            getList() {
                let option = {
                    ...this.form,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                };
                console.log(option);
                let url = "/base/vehicle/queryPage";
                this.$http.post(url, option).then(
                    ({ detail }) => {
                        let list = detail.list.map(item => {
                            return {
                                ...item,
                                ...item.sysOrg,
                                ...item.vehicleInfo
                            };
                        });
                        this.dataList = list;
                        this.total = detail.total;
                        this.loading = false;
                        this.$nextTick(() => {
                            let chooseList = this.dataList.filter(one => this.batchChooseList.some(item => item.vehicleId == one.vehicleId));
                            chooseList.forEach(item => {
                                this.$refs.dtable.toggleRowSelection(item, true);
                            });
                        });
                    },
                    () => {
                        this.loading = false;
                    }
                );
            },
            init() {
                this.visible = true;
                this.oncheck();
            },
            // 勾选数据
            selectchange(selection) {
                let newList = [];
                this.batchChooseList.forEach(item => {
                    if (!this.dataList.some(one => one.vehicleId == item.vehicleId) || selection.some(one => one.vehicleId == item.vehicleId)) {
                        newList.push(item);
                    }
                });
                selection.forEach(item => {
                    if (!newList.some(one => one.vehicleId == item.vehicleId)) {
                        newList.push(item);
                    }
                });
                this.batchChooseList = newList;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            cancel() {
                this.visible = false;
                console.log("cancel");
            },
            // 确定选择
            selectSure() {
                if (this.batchChooseList.length === 0) {
                    this.$message({
                        message: "请选择车辆",
                        type: "warning"
                    });
                } else {
                    this.visible = false;
                    this.$emit("select", this.batchChooseList);
                }
            }
        },
        data() {
            return {
                visible: false,
                loading: false,

                form: {
                    plateNum: "",
                    orgId: ""
                },
                pageIndex: 1,
                pageSize: 10,
                total: 0,

                batchChooseList: [],
                dataList: []
            };
        },
        components: {
            TreeCom
        }
    };
</script>

<style></style>
